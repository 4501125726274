*{
    box-sizing: border-box;
    font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
}
.page{
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    -webkit-flex-direction: column;
    flex-direction: column;

}
#background-container{
    background-size: 100% auto;
    color: rgba(0, 0, 0, 1);
    height: 208px;
}

@media only screen and (min-width: 768px) {
    /* For tablets: */
    #background-container{
        background-size: cover;
        height: 278px;
    }
}

@media only screen and (min-width: 1024px) {
    #background-container{
        background-size: cover;
        background-position: 0 -35px;
        height: 408px;
    }
}

.form-check-input.emoji-input {
    margin-left: 20px;
    margin-top: 20px;
}

.emojiContainer {
    height: 50px;
    width: 50px;
}

.emojiOptionContainer {
    margin-bottom: 20px;
}

.form-content{
    color: rgba(0,0,0,.87);
    padding: 34px 0;
}
.form-container{
    margin-top: -100px;
    margin: auto;
    max-width: 90vw;
    width: 640px;
    background-color: #fff;
    margin-bottom: 2rem;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.37);
    word-wrap: break-word;
}
.header-container {
    margin-bottom: 22px;
    padding: 34px 1rem 0 2rem;
}
.header-container{
    color: rgba(0,0,0,.87);

}
.header-container .subtitle{
    white-space: pre-wrap;
    line-height: 135%;
    margin-top: 22px;
    font-size: 13px;
}

.header-container .subtitle .danger-text{
    color: #d93025;
    margin-top: 15px;
}

.question-list {
    padding: 0 1rem;
    color: rgba(0,0,0,.87);
}

.question-list .question-item{
    background-color: transparent;
    margin-top: 2px;
    padding: 1rem;
    page-break-inside: avoid;
    -webkit-transition: background-color 200ms cubic-bezier(0.0,0.0,0.2,1);
    transition: background-color 200ms cubic-bezier(0.0,0.0,0.2,1);
}

.question_text{
    font-size: 1.1rem;
    -webkit-box-align: start;
    box-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    -webkit-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    min-width: 0%;
    position: relative;

    margin-bottom: 20px;
}
.question-options{
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
}
.text-field {
    border: 0;
    border-bottom: 1px solid;
    border-radius: 0;
    outline-style: none;
    outline: 0;
    outline-offset: -2px;
    box-shadow: none !important;
}
.radio-align-vertical{
    flex:2;
    display: flex;
    justify-content: space-around;
}
.emoji-align-vertical {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}
.custom-header{
    font-size: 1.3rem;
    padding: 8px 8px 8px 2rem;
    position: relative;
    margin-left: -2rem;
    width: fit-content;
    font-weight: 500;
    margin-bottom: 20px;
}

.not-required {
    display: none;
}
.required::after{
    content: "*";
    font-size: 20px;
    line-height: 135%;
    word-break: break-word;
    color: #d93025;
}
.nested-controls{
    padding: 1rem 0;
}
.order-wrapper{

    margin: 0 2rem;
}
.order-wrapper .summary-header{
    font-weight: 500;
    font-size: 1.1rem;
    padding: 1rem 0;

}
.order-items {
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow: scroll;
}

.order-item {
    display: flex;
    justify-content: space-around;
    padding: 1rem 0;
    width:100%;
}
.order-item.head{
    font-weight: 700;
    padding-bottom: 2rem !important;
}
.items{
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.combo-header {
    font-weight: 500;
    padding: 0 0 1rem 0;
}

.option-align-vertical{
    display: flex;
    flex-direction: column-reverse;
    align-content: baseline;
}
.no-margin{
    margin: 0;
}
.horizontal-radio-text{
    flex: 1 1 0%;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1rem;
}

    /* ------------- RADIO BUTTON CUSTOM --------------- */

.customRadio input[type="radio"] {
    display: none;
}
.form-check-label {
    position: relative;
}
.customRadio .form-check-label::before {
    content: '';
    height: 14px;
    width: 14px;
    border: 1px solid #ed1d25;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate(-50%, 0);
    background: #fff;
}
.customRadio .form-check-label::after {
    content: '';
    height: 8px;
    width: 8px;
    display: inline-block;
    background-color: #ed1d25;
    border-radius: 50%;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transition: all 0.2s ease-in-out;
    transform: translate(-50%, 0) scale(0);
}
.customRadio .emoji-input:checked + .form-check-label::after {
    transform: translate(-50%, 0) scale(1);
}
.customRadio .form-check-label.yellow::before {
    border-color: #fbb03b;
}
.customRadio .form-check-label.green::before {
    border-color: #8cc73f;
}
.customRadio .form-check-label.yellow::after {
    background-color: #fbb03b;
}
.customRadio .form-check-label.green::after {
    background-color: #8cc73f;
}
.radio-counter {
    font-size: 8px;
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translate(-50%, 0);
}
/* RESPONSIVE */
@media only screen and (max-width: 767px) {
    .question-list {
        padding: 0;
    }
    .emoji-align-vertical {
        flex-wrap: initial;
    }
    .emojiContainer {
        height: 29px;
        width: 29px;
    }
    .customRadio .form-check-label::before {
        height: 10px;
        width: 10px;
    }
    .customRadio .form-check-label::after {
        height: 5px;
        width: 5px;
    }
    .radio-counter {
        font-size: 7px;
        bottom: 2px;
    }
}